import { TimelineLite, TweenLite, TweenMax, Linear, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

$(function () {

 let deviceWidth = $(window).width();

 gsap.registerPlugin(ScrollTrigger);
 
 gsap.config({nullTargetWarn:false});
 gsap.registerPlugin(ScrollTrigger)


 if(deviceWidth > 1030) {


  // gsap.to('h1', {
  //   scrollTrigger: {
  //     trigger: '.banner ',
  //     start: 'bottom',
  //     markers: false,
  //     scrub: 1,
  //     start: '0', 
  //   },
  //   y: -150,
  // })

 gsap.to('.intouch', {
    scrollTrigger: {
      trigger: '.banner',
      start: 'bottom',
      markers: false,
      scrub: 1,
      start: '-=100',
    },
    y: 150,
  })



  const boxtitle = document.querySelectorAll(".box-title");
 
  boxtitle.forEach(function(item) {       
      const text = item.querySelector("span.bg");
      gsap.to(text, {
        scrollTrigger: {
          trigger: item,        
          markers: false,
          scrub: 1,
          start: '-=500',       
          // start: '-=100',
        },
        x: 50,
        rotation: 5
      })     
    });

    // const serviceitems = document.querySelectorAll(".service-item");
 
    // serviceitems.forEach(function(item) {       
    //    // const text = item.querySelector("span.bg");
    //     gsap.to(item, {
    //       scrollTrigger: {
    //         trigger: item,
          
    //         markers: false,
    //         scrub: 1,
    //         start: 'top bottom',
          
         
    //       },
    //       y: -30,
    //     })     
    //   });


    







 const parallaxContainer = document.querySelector('.parallax-container')
 const parallaxItems = document.querySelectorAll('.parallax')
 const fixer = 0.003
 document.addEventListener('mousemove', function (event) {
   if(!parallaxContainer){return}
   const pageX =event.pageX - parallaxContainer.getBoundingClientRect().width * 0.5
   const pageY =event.pageY - parallaxContainer.getBoundingClientRect().height * 0.5
   for (let i = 0; i < parallaxItems.length; i++) {
     const item = parallaxItems[i]
     const speedX = item.getAttribute('data-speed-x')
     const speedY = item.getAttribute('data-speed-y')
     const speedZ = item.getAttribute('data-speed-z')
     const scale = item.getAttribute('data-speed-scale')
     const rotate = item.getAttribute('data-speed-rotate')
     let data={
      x: (item.offsetLeft +item.offsetTop+ pageX * speedX) * fixer,
      y: (item.offsetTop +item.offsetLeft+ pageY * speedY) * fixer,
      z: (item.offsetTop + pageY * speedZ) * 0.02,
      scale: 1,
      rotate: rotate*pageY*fixer
    }
    if(scale>0) {
      data.scale=scale*pageY*fixer
    }
    console.log(data);
     TweenLite.set(item,data)
   }
 })

 }
});
